<template>
  <div>
    <t-dish v-for="product in products" :product="product" :key="product.id" />
  </div>
</template>

<script>
export default {
  name: "t-list-products",
};
</script>
