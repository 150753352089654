<template>
  <footer class="footer bg-green">
    <div class="footer__container _container">
      <div class="footer__buttons">
        <button class="btn btn-red" @click="this.$router.push('/products')">{{ $t("buttons.back") }}</button>
        <button class="btn btn-beige" @click="sendOrder()">{{ $t("buttons.pay") }}</button>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "t-footer-order",
  methods: {
    sendOrder: function () {
      this.$router.push("/payment");
    },
  },
};
</script>
