import { createRouter, createWebHistory } from "vue-router";
import Main from "./pages/Main";
import Welcome from "./pages/Welcome";
import Products from "./pages/Products";
import Product from "./pages/Product";
import OrderDetailed from "./pages/OrderDetailed";
import Payment from "./pages/Payment";
import NotWorking from "./pages/NotWorking";
import СatalogSets from "./pages/СatalogSets";

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Main,
    },
    {
      path: "/welcome",
      component: Welcome,
    },
    {
      path: "/",
      component: Welcome,
    },
    {
      path: "/products",
      component: Products,
    },
    {
      path: "/product/:id",
      component: Product,
    },
    {
      path: "/cart/",
      component: OrderDetailed,
    },
    {
      path: "/payment",
      component: Payment,
    },
    {
      path: "/not-working/",
      component: NotWorking,
    },
    {
      path: "/fast-sets",
      component: СatalogSets,
    },
  ],
});
