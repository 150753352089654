<template>
  <header class="header">
    <!-- <div class="header__block">
      <t-logo />
    </div> -->
    <div class="header__banner">
      <img :src="require(`@/assets/img/main/` + img)" :alt="img" />
    </div>
  </header>
</template>

<script>
export default {
  name: "t-header",
  data() {
    return {
      img: `banner.png`,
    };
  },
};
</script>

<style lang="scss" scoped>
.logo {
  justify-content: flex-start;
}
</style>
