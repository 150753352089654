<template>
  <div class="slider">
    <div class="slider__container _container">
      <h2 class="slider__title">{{ $t("btn_add_order") }}</h2>
      <swiper class="blocks" :modules="modules" :slides-per-view="4" :space-between="28" navigation :loop="true" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide class="block" v-for="rd in recommendedProducts" :key="rd.id">
          <div class="block__img">
            <router-link :to="`/product/${rd.id}`">
              <img :src="rd.image" alt="1" />
            </router-link>
          </div>
          <div class="block__body">
            <router-link :to="`/product/${rd.id}`" class="block__title">{{ product.real_name }}</router-link>
            <div class="block__info"><img src="@/assets/img/svg/info.svg" alt="info" :title="rd.real_name" /></div>
            <div class="counter">
              <button class="counter__minus" @click="delCounter"><img src="@/assets/img/svg/minus.svg" alt="minus" /></button>
              <span class="counter__counts">{{ 1 }} шт.</span>
              <span class="counter__line"></span>
              <span class="counter__price">{{ 1 * 1 }} ₽</span>
              <button class="counter__plus"><img src="@/assets/img/svg/plus.svg" alt="plus" /></button>
            </div>
            <button class="btn btn-green">{{ $t("btn_to_order") }}</button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "t-slider",
  props: ["recommendedProducts"],
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, A11y],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/slider";
</style>
