<template>
  <footer class="footer">
    <div class="footer__body">
      <div class="footer__header">
        <div class="footer__container _container">
          <div class="footer__info">
            <h2 class="footer__title">{{ $t("footer_order.title") }} - {{ orderObj.order_type_name }}</h2>
            <div class="total-sum__price">
              {{ $t("footer_order.total") }} <span v-if="orderObj.discount_amount <= 0">{{ orderObj.full_price }}</span>
              <span v-if="orderObj.discount_amount > 0"
                ><span style="text-decoration: line-through">{{ orderObj.products_price }}</span> {{ orderObj.full_price }}</span
              >
              ₽
            </div>
          </div>
        </div>
      </div>
      <!--      <t-show-popup v-if="isShowOrder" />-->
      <!--      <div class="footer__orders" v-if="isHideOrder">-->
      <!--        <div class="footer__container _container">-->
      <!--          <div class="orders">-->
      <!--            <template v-if="ordersHall.length">-->
      <!--              <t-order-hall v-for="order in ordersHall" :order="order" :key="order.id" />-->
      <!--            </template>-->
      <!--            <template v-else>-->
      <!--              <div class="status">{{ status }}</div>-->
      <!--            </template>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="footer__container _container">
        <div class="footer__buttons">
          <button class="btn btn-red" @click="this.$router.push('/')">{{ $t("footer_order.cancel") }}</button>
          <button class="btn btn-green" @click="this.$router.push('/cart/')">{{ $t("footer_order.done") }}</button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-orders-hall",
  props: ["orderObj"],
  data() {
    return {
      isShowOrder: false,
      isHideOrder: true,
      arrow: `arrow-up.svg`,
    };
  },
  methods: {
    showPrice: function (product_id) {
      let output = 0;
      this.orderObj.items.forEach(function (item) {
        if (item.product.id == product_id) {
          console.log(item.price, item.quantity, item.price * item.quantity);
          output = item.product.price * item.quantity;
        }
      });
      return output;
    },
    getOrderItem: function (product_id) {
      let output = 0;
      this.orderObj.items.forEach(function (item) {
        if (item.product.id == product_id) {
          output = item.id;
        }
      });
      return output;
    },
    changeCategory: function (categoryId) {
      this.selectedCategoryId = categoryId;
      axios
        .get(api + `products/?category=` + this.selectedCategoryId)
        .then((response) => {
          this.products = response.data;
        })
        .catch((e) => {
          this.errors.cafes = e.message;
        });
    },
    addToOrder(order_id, product_id) {
      let self = this;
      axios.post(api + `order-add/`, { order_id: order_id, product_id: product_id }).then(function (response) {
        self.orderObj = response.data;
      });
    },
    changeOrderItem(order_id, order_item_id, type) {
      let self = this;
      axios
        .post(api + `order-change/`, {
          order_id: order_id,
          order_item_id: order_item_id,
          type: type,
        })
        .then(function (response) {
          self.orderObj = response.data;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.status {
  padding: 46px 45px 26px;
  @media (max-width: $ms4) {
    padding: 20px 20px 0 20px;
  }
}
.orders {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -16px;
  padding: 0px 0px 24px 0px;
  @media (max-width: $ms2) {
    justify-content: center;
  }
  @media (max-width: $ms3) {
    margin: 0 auto;
    padding: 0px 0px 24px 0px;
  }
}
.block-counter {
  display: flex;
  align-items: center;
  .price {
    margin-right: 17px;
    font-weight: 700;
    @include adaptive-font(25, 14, 0);
    @include adaptive-value("line-height", 26, 15, 0);
  }
}
.footer__orders {
  .counter {
    width: 180px;
    box-shadow: none;
    &__counts {
      font-weight: 700;
      @include adaptive-font(20, 14, 0);
      @include adaptive-value("line-height", 21, 15, 0);
    }
  }
}
</style>
