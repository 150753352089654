<template>
  <t-top />
  <div class="welcome">
    <div class="welcome__container _container">
      <div class="welcome__body">
        <h1 class="welcome__title">{{ $t("title") }}</h1>
        <h2 class="welcome__subtitle">{{ $t("subtitle") }}</h2>
        <div class="welcome__orders">
          <div
            class="welcome__order"
            @click="selectPickupType('pickup_on_tray')"
          >
            <div class="welcome__img">
              <img :src="require(`@/assets/img/in-hall.png`)" alt="В зале" />
            </div>
            <p class="welcome__text">{{ $t("how_order.method1") }}</p>
          </div>
          <div
            class="welcome__order"
            @click="selectPickupType('packed_pickup')"
          >
            <div class="welcome__img">
              <img
                :src="require(`@/assets/img/with-you.png`)"
                alt="with-you.png"
              />
            </div>
            <p class="welcome__text">{{ $t("how_order.method2") }}</p>
          </div>
        </div>
        <!--        <div @click="selectPickupType('express')" class="fast-sets" v-if="cafe_slug == 'it-park'">-->
        <!--          <div class="fast-sets__img">-->
        <!--            <img :src="require(`@/assets/img/${flower_talk}`)" :alt="flower_talk" />-->
        <!--          </div>-->
        <!--          <div class="fast-sets__text">{{ flower_text }}</div>-->
        <!--        </div>-->
        <div class="welcome__prices">
          <p>{{ $t("title_price") }}</p>
        </div>
        <!--<h2 class="welcome__menu">{{ $t("title_select") }}</h2>
        <div class="welcome__languages">
          <button class="btn" @click="selectLang1"><img :src="require(`@/assets/img/svg/eng.svg`)" :alt="$t('langs.lang1')" />{{ $t("langs.lang1") }}</button>
          <button class="btn" @click="selectLang2"><img :src="require(`@/assets/img/svg/rus.svg`)" :alt="$t('langs.lang2')" />{{ $t("langs.lang2") }}</button>
          <button class="btn" @click="selectLang3"><img :src="require(`@/assets/img/svg/tat.jpg`)" :alt="$t('langs.lang3')" />{{ $t("langs.lang3") }}</button>
        </div>-->
      </div>
    </div>
  </div>
  <t-ornament />
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";
import { useI18n } from "vue-i18n";
export default {
  name: "t-welcome",
  data() {
    return {
      flower_talk: `flower-talk.png`,
      flower_text: `Быстрые наборы`,
      cafe_slug: "",
    };
  },
  methods: {
    selectPickupType(pickup_type) {
      let self = this;
      axios
        .get(api + `orders/?pickup_type=` + pickup_type)
        .then(function (response) {
          localStorage.setItem("orderId", response.data.id);
          var pushRoute =
            pickup_type == "express" ? "/fast-sets" : "/products/";
          self.$router.push(pushRoute);
        });
    },
  },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    const selectLang1 = () => {
      locale.value = "en";
      localStorage.setItem("lang", locale.value);
    };
    const selectLang2 = () => {
      locale.value = "ru";
      localStorage.setItem("lang", locale.value);
    };
    const selectLang3 = () => {
      locale.value = "tat";
      localStorage.setItem("lang", locale.value);
    };
    return {
      selectLang1,
      selectLang2,
      selectLang3,
    };
  },
  mounted() {
    let host = window.location.href;
    let cafe_slug = host.split("/");
    this.cafe_slug = cafe_slug[2].split(".")[0];

    if (host.indexOf("localhost") != -1) {
      this.cafe_slug = "it-park";
    }
    console.log(this.cafe_slug);
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";

.welcome {
  @include adaptive-value("padding-bottom", 270, 20, 0);
  background-color: $green;
  height: 110vw;
  overflow: hidden;
  @media (max-width: 1080px) {
    height: 143vw;
  }
  @media (max-width: $ms3) {
    padding-top: 50px;
    height: 85vh;
  }

  &__body {
    text-align: center;
    color: #fff;
    font-weight: 400;
  }

  &__title {
    font-weight: 700;
    text-transform: uppercase;
    @include adaptive-font(100, 30, 0);
    @include adaptive-value("line-height", 120, 50, 0);
    white-space: pre;
  }

  &__subtitle {
    @include adaptive-font(50, 18, 0);
    @include adaptive-value("line-height", 51, 20, 0);
    @include adaptive-value("margin-top", 90, 20, 0);
  }

  &__orders {
    display: flex;
    justify-content: center;
    margin: 109px -33px 0;
    @media (max-width: $ms3) {
      margin: 50px -15px 0;
    }
  }

  &__order {
    background: #fffff7;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 0px 10px #206350;
    width: 380px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    margin: 0 33px;
    cursor: pointer;
    @media (max-width: $ms3) {
      margin: 0 15px;
    }
    @media (max-width: $ms4) {
      height: 250px;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 0px 10px #319b7d;
      transition: 0.2s ease 0.2s;

      .welcome__text {
        color: #319b7d;
        transition: 0.2s ease 0.2s;
      }
    }
  }

  &__img {
    width: 270px;
    height: 200px;
    overflow: hidden;
    flex: 1 1 auto;
    @media (max-width: $ms3) {
      width: 200px;
      height: 130px;
    }
    @media (max-width: $ms4) {
      width: 150px;
      height: 80px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    font-weight: 700;
    text-align: center;
    color: #28735f;
    @include adaptive-font(30, 14, 0);
    @include adaptive-value("line-height", 31, 15, 0);
  }

  &__prices {
    @include adaptive-font(30, 12, 0);
    @include adaptive-value("line-height", 31, 20, 0);
    @include adaptive-value("margin-top", 109, 50, 0);
  }

  &__menu {
    @include adaptive-font(50, 20, 0);
    @include adaptive-value("line-height", 51, 20, 0);
    margin-top: 30px;
  }

  &__languages {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px -22px 0px;
    @media (max-width: $ms3) {
      margin: 20px -10px 0px;
    }

    .btn {
      img {
        width: 30px;
        height: 20px;
        margin-right: 30px;
      }
    }
  }

  .btn {
    width: 276px;
    height: 111px;
    background: #fffff7;
    border-radius: 4.45098px;
    box-shadow: 0 0 0px 7px #206350;
    color: #28735f;
    display: flex;
    justify-content: center;
    margin: 22px;
    @media (max-width: $ms3) {
      flex: 1 1 auto;
      height: 50px;
      margin: 10px;
    }

    &:hover,
    &:focus {
      background: #206350;
      color: #fff;
      transition: 0.2s ease 0.2s;
    }
  }
  .fast-sets {
    width: 85%;
    height: auto;
    border-radius: 31px;
    background-color: #fffff7;
    display: flex;
    align-items: center;
    padding: 3rem 5rem;
    box-shadow: inset 0 0 0 10px #af5840;
    margin: 3rem auto 0;
    cursor: pointer;
    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 10px #d36d51;
      transition: 0.2s ease 0.2s;
      .fast-sets__text {
        color: #d36d51;
        transition: 0.2s ease 0.2s;
      }
    }
    &__img {
      width: 211.96px;
      height: 180px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__text {
      font-weight: 800;
      line-height: 64.11px;
      text-align: center;
      color: #d36d51;
      font-size: clamp(14px, 5vw, 47px);
      flex: 1 1 auto;
    }
  }
}
</style>
