<template>
  <t-header />
  <div class="dish pt">
    <div class="dish__container _container">
      <button
        class="btn btn-arrow"
        @click="$router.push('/products/?category=' + product.category.id)"
      >
        <img src="@/assets/img/svg/arrow.svg" alt="arrow" />{{
          $t("btn_arrow2")
        }}
      </button>
      <div class="card-dish">
        <div class="card-dish__blocks">
          <div class="card-dish__view">
            <div class="card-dish__img">
              <img
                :src="
                  product.image
                    ? product.image
                    : require(`@/assets/img/${nophoto}`)
                "
                :alt="product.real_name"
              />
            </div>
            <div class="card-dish__values">
              <div class="card-dish__cols-vals">
                <div class="card-dish__col-val">
                  <span>{{ product.energy }}</span>
                  <span>{{ $t("structures.energy") }}</span>
                </div>
                <div class="card-dish__col-val">
                  <span>{{ product.proteins }}</span>
                  <span>{{ $t("structures.proteins") }}</span>
                </div>
                <div class="card-dish__col-val">
                  <span>{{ product.fat }}</span>
                  <span>{{ $t("structures.fat") }}</span>
                </div>
                <div class="card-dish__col-val">
                  <span>{{ product.carbohydrates }}</span>
                  <span>{{ $t("structures.carbohydrates") }}</span>
                </div>
              </div>
              <div class="card-dish__weights">
                <div class="card-dish__weight active">
                  {{ Math.round(product.weight) }}
                  {{ product.weight_measure === "ml" ? "мл" : "г." }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-dish__body">
            <h2 class="card-dish__subtitle">
              {{ product.category.real_name }}
            </h2>
            <div class="card-dish__text">
              <h1 class="card-dish__title">{{ product.real_name }}</h1>
              <p class="card-dish__desc" v-html="product.description"></p>
            </div>
            <div class="card-dish__change-order">
              <div
                class="card-dish__change"
                v-if="
                  orderObj.products_ids.indexOf(product.id) != -1 &&
                  (product.modifiers.length === 0 || product.modifiers_as_hint)
                "
              >
                <div class="counter">
                  <button
                    class="counter__minus"
                    @click="
                      changeOrderItem(
                        orderObj.id,
                        getOrderItem(product.id),
                        'decrease'
                      )
                    "
                  >
                    <img src="@/assets/img/svg/minus.svg" alt="minus" />
                  </button>
                  <span class="counter__counts"
                    >{{ showPrice(product.id) }} ₽</span
                  >
                  <button
                    class="counter__plus"
                    @click="
                      changeOrderItem(
                        orderObj.id,
                        getOrderItem(product.id),
                        'increase'
                      )
                    "
                  >
                    <img src="@/assets/img/svg/plus.svg" alt="plus" />
                  </button>
                </div>
              </div>
              <template
                v-if="
                  product.modifiers.length === 0 || product.modifiers_as_hint
                "
              >
                <button
                  class="btn btn-green"
                  @click="addToOrder(orderObj.id, product.id)"
                  v-if="orderObj.products_ids.indexOf(product.id) == -1"
                >
                  {{ $t("add_cancel.add") }} {{ product.price }} ₽
                </button>
              </template>
              <template
                v-if="
                  product.modifiers.length > 0 && !product.modifiers_as_hint
                "
              >
                <div
                  :class="['tab', { active: currentTab.id === modifier.id }]"
                  class="modifier"
                  v-for="modifier in product.modifiers"
                  @click="handleTabClick(modifier)"
                  :key="modifier.id"
                >
                  <div>{{ modifier.name }}</div>
                </div>
                <button
                  v-if="!currentTab.product"
                  class="btn btn-green"
                  @click="
                    modifierAdd(
                      currentTab.id,
                      orderObj.id,
                      'increase',
                      product.id,
                      getOrderItem(product.id)
                    )
                  "
                >
                  {{ $t("add_cancel.add") }} {{ product.price }} ₽
                </button>
                <div class="counter modifier__price" v-if="currentTab.product">
                  <button
                    class="counter__minus"
                    @click="
                      modifierChange(
                        currentTab.id,
                        orderObj.id,
                        'decrease',
                        product.id,
                        currentTab.product.id
                      )
                    "
                  >
                    <img src="@/assets/img/svg/minus.svg" alt="minus" />
                  </button>
                  <span class="counter__counts">{{ currentTab.price }} ₽</span>
                  <button
                    class="counter__plus"
                    @click="
                      modifierChange(
                        currentTab.id,
                        orderObj.id,
                        'increase',
                        product.id,
                        currentTab.product.id
                      )
                    "
                  >
                    <img src="@/assets/img/svg/plus.svg" alt="plus" />
                  </button>
                </div>
              </template>
            </div>
            <t-changed v-if="isIndigrients" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="slider">
    <div class="slider__container _container">
      <h2 class="slider__title">{{ $t("btn_add_order") }}</h2>
      <swiper
        class="blocks"
        :modules="modules"
        :slides-per-view="4"
        :space-between="28"
        navigation
        :pagination="{ clickable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide
          class="block"
          v-for="rd in recommendedProducts"
          :key="rd.id"
        >
          <div class="block__img">
            <router-link :to="`/product/${rd.id}`">
              <img
                :src="rd.image ? rd.image : require(`@/assets/img/${nophoto}`)"
                alt="1"
              />
            </router-link>
          </div>
          <div class="block__body">
            <router-link :to="`/product/${rd.id}`" class="block__title">{{
              rd.real_name
            }}</router-link>
            <div
              class="card-dish__change"
              v-if="orderObj.products_ids.indexOf(rd.id) != -1"
            >
              <div class="counter">
                <button
                  class="counter__minus"
                  @click="
                    changeOrderItem(
                      orderObj.id,
                      getOrderItem(rd.id),
                      'decrease'
                    )
                  "
                >
                  <img src="@/assets/img/svg/minus.svg" alt="minus" />
                </button>
                <span class="counter__counts">{{ showPrice(rd.id) }} ₽</span>
                <button
                  class="counter__plus"
                  @click="
                    changeOrderItem(
                      orderObj.id,
                      getOrderItem(rd.id),
                      'increase'
                    )
                  "
                >
                  <img src="@/assets/img/svg/plus.svg" alt="plus" />
                </button>
              </div>
            </div>
            <button
              class="btn btn-green"
              @click="addToOrder(orderObj.id, rd.id)"
              v-if="orderObj.products_ids.indexOf(rd.id) == -1"
            >
              {{ $t("add_cancel.add") }} {{ rd.price }} ₽
            </button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <t-orders-hall :orderObj="orderObj" />
</template>

<script>
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-dish",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y],
    };
  },
  data() {
    return {
      isChangeOrder: true,
      isIndigrients: false,
      orderId: localStorage.getItem("orderId"),
      counter: 1,
      product: {},
      errors: [],
      orderObj: {},
      recommendedProducts: [],
      currentTab: {},
      nophoto: "nophoto.jpg",
    };
  },
  methods: {
    handleTabClick(tab) {
      this.currentTab = {
        ...tab,
        product: this.orderObj.items.find(
          (item) => item.selected_modifier === tab.id
        ),
      };
      this.currentTab.price =
        this.product.price * this.currentTab.product.quantity;
    },
    modifierChange(mod_id, order_id, type, product_id, order_item_id) {
      let self = this;
      axios
        .post(api + `order-change/`, {
          order_id,
          product_id,
          mod_id,
          type,
          order_item_id,
        })
        .then(function (response) {
          self.orderObj = response.data;
          self.handleTabClick(self.currentTab);
        });
    },
    modifierAdd(mod_id, order_id, type, product_id, order_item_id) {
      let self = this;
      axios
        .post(api + `order-add/`, {
          order_id,
          product_id,
          mod_id,
          type,
          order_item_id,
        })
        .then(function (response) {
          self.orderObj = response.data;
          self.handleTabClick(self.currentTab);
        });
    },
    showPrice: function (product_id) {
      let output = 0;
      this.orderObj.items.forEach(function (item) {
        if (item.product.id == product_id) {
          console.log(item.price, item.quantity, item.price * item.quantity);
          output = item.product.price * item.quantity;
        }
      });
      return output;
    },
    getOrderItem: function (product_id) {
      let output = 0;
      this.orderObj.items.forEach(function (item) {
        if (item.product.id == product_id) {
          output = item.id;
        }
      });
      return output;
    },
    addToOrder(order_id, product_id) {
      let self = this;
      axios
        .post(api + `order-add/`, {
          order_id: order_id,
          product_id: product_id,
        })
        .then(function (response) {
          self.orderObj = response.data;
        });
    },
    changeOrderItem(order_id, order_item_id, type) {
      let self = this;
      axios
        .post(api + `order-change/`, {
          order_id: order_id,
          order_item_id: order_item_id,
          type: type,
        })
        .then(function (response) {
          self.orderObj = response.data;
        });
    },
  },
  created() {
    let productId = this.$route.params.id;
    let self = this;

    axios.get(api + `orders/` + self.orderId + "/").then(function (response) {
      self.orderObj = response.data;
      self.handleTabClick(self.currentTab);
    });

    axios
      .get(api + `products/${productId}`)
      .then((response) => {
        self.product = response.data;
        if (
          self.product.modifiers.length > 0 &&
          !self.product.modifiers_as_hint
        ) {
          self.currentTab = self.product.modifiers[0];
        }
        self.handleTabClick(self.currentTab);
      })
      .catch((e) => {
        this.errors.push(e);
      });
    axios
      .get(api + `recommended-products/?product_id=${productId}`)
      .then((response) => {
        self.recommendedProducts = response.data;
        console.log(response);
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/slider";

.dish {
  @include adaptive-value("padding-bottom", 306, 100, 0);
  height: 138vw;
  @media (max-width: 1080px) {
    height: 87vw;
  }
  @media (max-width: $ms3) {
    height: auto;
    padding-bottom: 0;
  }
  &__content {
    @include adaptive-value("margin-top", 56, 20, 0);
  }

  &__supplement-order {
    color: #000;
    @include adaptive-font(30, 18, 0);
    @include adaptive-value("line-height", 31, 20, 0);
  }

  .blocks {
    margin: 0 auto;
    padding-bottom: 114px;
    position: relative;

    .block {
      flex: 0 0 23%;
      margin: 20px auto 0;
      @media (max-width: $ms3) {
        flex: 0 0 35%;
      }
      @media (max-width: $ms4) {
        flex: 0 0 50%;
      }
    }
  }

  .modifier {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    margin-top: 20px;
    font-family: "Arial";
    cursor: pointer;
    transition: all 0.2s linear;

    &.active {
      color: #fff;
      background: #28735f;
    }

    &:hover {
      background: #28735f;
      color: #fff;
    }

    &__price {
      margin-top: 20px;
      width: 100%;
    }
  }
}
</style>
