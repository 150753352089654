<template>
  <div class="keyboard">
    <div class="keyboard__block">
      <div class="keyboard__container _container">
        <div class="keyboard__body">
          <input type="text" v-model="message" :placeholder="$t('cart.input_promo')" class="keyboard__title" />
          <div class="keyboard__keys" @click="typeLetter">
            <button class="keyboard__key key">1</button>
            <button class="keyboard__key key">2</button>
            <button class="keyboard__key key">3</button>
            <button class="keyboard__key key">4</button>
            <button class="keyboard__key key">5</button>
            <button class="keyboard__key key">6</button>
            <button class="keyboard__key key">7</button>
            <button class="keyboard__key key">8</button>
            <button class="keyboard__key key">9</button>
            <button class="keyboard__key key">0</button>
            <button class="btn btn-green key"><img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" /></button>
            <button class="btn btn-green btn-long key">{{ $t("cart.btn_clear") }}</button>
            <button class="keyboard__key key">й</button>
            <button class="keyboard__key key">ц</button>
            <button class="keyboard__key key">у</button>
            <button class="keyboard__key key">к</button>
            <button class="keyboard__key key">е</button>
            <button class="keyboard__key key">н</button>
            <button class="keyboard__key key">г</button>
            <button class="keyboard__key key">ш</button>
            <button class="keyboard__key key">щ</button>
            <button class="keyboard__key key">з</button>
            <button class="keyboard__key key">х</button>
            <button class="keyboard__key key">ъ</button>
            <button class="keyboard__key key">ё</button>
            <button class="keyboard__key key">ф</button>
            <button class="keyboard__key key">ы</button>
            <button class="keyboard__key key">в</button>
            <button class="keyboard__key key">а</button>
            <button class="keyboard__key key">п</button>
            <button class="keyboard__key key">р</button>
            <button class="keyboard__key key">о</button>
            <button class="keyboard__key key">л</button>
            <button class="keyboard__key key">д</button>
            <button class="keyboard__key key">ж</button>
            <button class="keyboard__key key">э</button>
            <button class="keyboard__key key">я</button>
            <button class="keyboard__key key">ч</button>
            <button class="keyboard__key key">с</button>
            <button class="keyboard__key key">м</button>
            <button class="keyboard__key key">и</button>
            <button class="keyboard__key key">т</button>
            <button class="keyboard__key key">ь</button>
            <button class="keyboard__key key">б</button>
            <button class="keyboard__key key">ю</button>
            <button class="keyboard__key key">_</button>
            <button class="btn btn-green key"><img src="@/assets/img/svg/space.svg" alt="space" class="space" /></button>
            <button class="btn btn-green btn-long key">{{ $t("cart.btn_apply") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer">
    <div class="footer__container _container">
      <div class="footer__buttons">
        <button class="btn btn-red">{{ $t("cart.cancel") }}</button>
        <button class="btn btn-green">{{ $t("cart.apply_promo") }}</button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "t-keyboard",
  data() {
    return {
      message: ``,
    };
  },
  methods: {
    typeLetter() {
      if (event.target.className !== "keyboard__keys") {
        let key = event.target.innerHTML || event.target.className;
        if (key === "clear") this.message = this.message.substr(0, this.message.length - 1);
        else if (key === "Очистить") this.message = "";
        else if (key === "space") this.message += " ";
        else this.message += key.toUpperCase();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.keyboard {
  background: $green;
  border-bottom: 1px solid rgba(40, 115, 95, 0.3);
  &__block {
    background: #fffff7;
    padding: 70px 90px 88px;
    border-radius: 50px 50px 0 0;
    margin-top: 20px;
    @media (max-width: $ms1) {
      padding: 50px 0;
    }
    @media (max-width: $ms3) {
      padding: 20px 0;
    }
  }
  &__body {
    padding: 0 2rem;
    @media (max-width: $ms3) {
      padding: 0;
    }
  }
  &__title {
    color: #000000;
    opacity: 0.2;
    @include adaptive-font(30, 14, 0);
    @include adaptive-value("line-height", 31, 15, 0);
    width: 100%;
    @media (max-width: $ms3) {
      padding: 0px 0px 0px 1rem;
    }
  }
  &__input {
    input {
      background: none;
      border: 1px solid #28735f;
      border-radius: 10px;
      width: 100%;
      height: 80px;
      padding: 0 23px;
      color: #28735f;
      @include adaptive-font(30, 14, 0);
      @include adaptive-value("line-height", 31, 20, 0);
      @media (max-width: $ms4) {
        height: 55px;
      }
    }
  }
  &__keys {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 32px -5px 0;
    @media (max-width: $ms3) {
      margin: 15px auto 0;
    }
    .btn {
      width: 62px;
      height: 62px;
      display: flex;
      justify-content: center;
      margin: 5px;
      overflow: hidden;
      img {
        padding: 100%;
      }
    }
    @media (max-width: 1046px) {
      .btn {
        order: 1;
      }
    }
    .btn-long {
      width: 136px;
      @include adaptive-font(25, 14, 0);
      @include adaptive-value("line-height", 26, 15, 0);
    }
  }
  &__key {
    background: none;
    width: 60px;
    height: 60px;
    border: 1px solid #28735f;
    border-radius: 7.66379px;
    @include adaptive-font(30, 14, 0);
    @include adaptive-value("line-height", 31, 20, 0);
    color: #28735f;
    margin: 5px;
    text-transform: uppercase;
    &:hover,
    &:focus {
      background: #28735f;
      color: #fff;
      transition: 0.2s ease 0.2s;
    }
  }
}
.keyboard__key,
.keyboard__keys .btn {
  height: 60px;
  width: 60px;
  @media (max-width: $ms4) {
    width: 45px;
    height: 45px;
  }
}
.keyboard__keys .btn-long {
  width: 128px;
}
.footer {
  background: #fffff7;
}
</style>
