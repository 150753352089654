<template>
  <div>
    <div class="counter" v-if="isCounts">
      <button class="counter__minus" @click="decrease"><img src="@/assets/img/svg/minus.svg" alt="minus" /></button>
      <span class="counter__price">{{ product.price * counts }} ₽</span>
      <button class="counter__plus" @click="increase"><img src="@/assets/img/svg/plus.svg" alt="plus" /></button>
    </div>
    <div class="btn-green add-to-cart" @click="addToOrder(orderObj.id, product.id)">{{ $t("cart.title") }}</div>
  </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-add-to-cart",
  props: ["product", "orderObj"],
  data: () => ({}),
  methods: {
    addToOrder(order_id, product_id) {
      let self = this;
      axios.post(api + `order-add/`, { order_id: order_id, product_id: product_id }).then(function (response) {
        self.orderObj = response.data;
      });
    },
    changeOrderItem(order_id, order_item_id, type) {
      let self = this;
      axios
        .post(api + `order-change/`, {
          order_id: order_id,
          order_item_id: order_item_id,
          type: type,
        })
        .then(function (response) {
          self.orderObj = response.data;
        });
    },
  },
};
</script>
