<template>
  <div class="add-cancel">
    <button class="btn btn-green">{{ $t("add_cancel.add") }}<span>129 ₽</span></button>
    <button class="btn btn-red">{{ $t("add_cancel.cancel") }}</button>
  </div>
</template>

<script>
export default {
  name: "t-add-cancel",
};
</script>
