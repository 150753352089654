<template>
  <div class="select-combo">
    <div class="select-combo__body">
      <div class="select-combo__select">
        <h3 class="select-combo__title">{{ $t("buttons.сhoose") }}</h3>
        <button class="btn btn-red">{{ $t("buttons.сancel") }}</button>
      </div>
      <div class="select-combo__blocks">
        <div class="blocks">
          <div class="block" v-for="dish in dishes" :key="dish">
            <div class="block__img">
              <img :src="require(`@/assets/img/combo/` + dish.img)" :alt="dish.img" />
            </div>
            <div class="block__body">
              <div class="block__text">
                <h4 class="block__title">{{ dish.title }}</h4>
                <p class="block__desc">{{ dish.desc }}</p>
              </div>
              <div class="block__bottom">
                <button class="block__btn">{{ $t("buttons.change") }}</button>
                <div class="counter">
                  <button class="counter__minus" @click="decrease"><img src="@/assets/img/svg/minus.svg" alt="minus" /></button>
                  <span class="counter__price">{{ 159 }} ₽</span>
                  <button class="counter__plus" @click="increase"><img src="@/assets/img/svg/plus.svg" alt="plus" /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-select-combo",
  data() {
    return {
      dishes: [
        { id: 1, img: `1.png`, title: `Комбо 1`, desc: `Курай с запеченной курицей, с томатами, сладким луком и соусом гриль` },
        { id: 2, img: `1.png`, title: `Комбо 2`, desc: `Курай с запеченной курицей, с томатами, сладким луком и соусом гриль` },
        { id: 3, img: `1.png`, title: `Комбо 3`, desc: `Курай с запеченной курицей, с томатами, сладким луком и соусом гриль` },
      ],
      counter: 1,
      price: 129,
    };
  },
  methods: {
    increase() {
      this.$store.commit("increase", this.product);
      this.$emit("increase", this.product);
    },
    decrease() {
      if (this.counts < 2) {
        this.isAddToCart = true;
        this.isCounts = false;
      }
      this.$store.commit("decrease", this.product);
      this.$emit("decrease", this.product);
    },
  },
  computed: {
    counts() {
      return this.$store.getters.productQuantity(this.product);
    },
  },
};
</script>
