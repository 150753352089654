<template>
  <div class="total-sum">
    <div class="total-sum__container _container">
      <div class="total-sum__discount" v-if="orderObj.discount_amount">
        {{ $t("footer_order.discount") }} <span> {{ orderObj.discount_amount }}</span> ₽
      </div>
      <div class="total-sum__price">
        {{ $t("footer_order.total") }}
        <span>{{ orderObj.full_price }} ₽</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-total-sum",
  props: ["orderObj"],
  data() {
    return {
      isDiscount: false,
    };
  },
};
</script>
