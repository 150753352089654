<template>
  <div class="order">
    <div class="order__text">
      <div class="order__title">{{ order.title }}</div>
    </div>
    <div class="block-counter">
      <div class="price">{{ counter * price }} ₽</div>
      <div class="counter">
        <button class="counter__minus" @click="delCounter"><img src="@/assets/img/svg/minus.svg" alt="minus" /></button>
        <span class="counter__counts">{{ counter }} шт</span>
        <button class="counter__plus" @click="addCounter"><img src="@/assets/img/svg/plus.svg" alt="plus" /></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-order-hall",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      counter: 1,
      price: 50,
    };
  },
  methods: {
    addCounter() {
      if (this.counter >= 99) return false;
      this.counter++;
    },
    delCounter() {
      if (this.counter <= 1) return false;
      this.counter--;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.order {
  flex: 0 0 46.9%;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 24px 16px 0;
  @media (max-width: $ms1) {
    flex: 0 0 45%;
  }
  @media (max-width: $ms2) {
    flex: 1 1 auto;
    margin: 24px auto 0;
  }
  @media (max-width: $ms4) {
    width: 100%;
    height: auto;
    padding: 20px;
    flex-direction: column;
  }
  &__text {
    display: flex;
    align-items: center;
    width: 189px;
    @media (max-width: $ms3) {
      width: 100%;
      padding: 0px 20px 0px 0px;
    }
    @media (max-width: $ms4) {
      padding: 0;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
    }
  }
  &__title {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    @media (max-width: $ms4) {
      font-size: 14px;
      line-height: 15px;
    }
  }
  &__price {
    width: 99px;
    height: 61px;
    font-weight: 700;
    color: #28735f;
    @include adaptive-font(25, 14, 0);
    @include adaptive-value("line-height", 26, 15, 0);
  }
}
</style>
